import React from "react";
import $ from "jquery";
import './Style.css';
import bscimage from './images/bscimages.svg.png'
import maticimg from './images/polygon.svg'
import ethimg from './images/Eth.png'
import dchainimg from './images/dchainimages.png'
import axios from 'axios';
import InputDataDecoder from 'ethereum-input-data-decoder'
import { homebridgeAbi } from './Abi/homebridgeabi'

import { BiArrowBack, BiLeftArrowAlt } from 'react-icons/bi';
import DataTable, { createTheme } from 'react-data-table-component';

import { BsWallet2 } from "react-icons/bs";
import { VscArrowSwap } from 'react-icons/vsc';
import DIClogo from "./images/dictys-logo.webp"
import moment from "moment";


createTheme('solarized', {
    text: {
        primary: '#000',
        secondary: '#000',
    },
    background: {
        default: '#eac3686e',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#FFFFFF34',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');


class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            setgettxdetails: [],
            connectedAccount: ""
        }
    }

    columns = [
        {
            name: "Date",
            selector: (row) => row.sno,
            sortable: true,
        },
        {
            name: "Tnx Hash",
            selector: (row) => <a href={row.link} target={"_blank"}>{row.hash}</a>,
            sortable: true,
        },
        {
            name: "From",
            selector: (row) => row.from,
            sortable: true,
            width: "200px"
        },
        {
            name: "To",
            selector: (row) => row.to,
            sortable: true,
            width: "200px"
        },
        {
            name: "Amount",
            selector: (row) => row.value,
            sortable: true,
        },

    ];

    handleSort = (column, sortDirection) => console.log("=====", column.selector, sortDirection);

    componentDidMount() {
        if (window.ethereum) {
            const chainaccounts = sessionStorage.getItem('chainaccounts')
            if(chainaccounts == null) {
                $("#hidedisconnect").hide(); 
                $("#hideconnect").show(); 
                this.setState({
                    connectedAccount: "CONNECT WALLET"
                })
            } 
            else {
                let accountslice = chainaccounts.slice(0, 7) + ".." + chainaccounts.slice(-5);
                this.setState({
                    connectedAccount: accountslice
                })
            }
        }
        this.getchainid()
    }

    connectWeb3Wallet = async () => {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        sessionStorage.setItem('chainaccounts', accounts[0])
        let accountslice = accounts[0].slice(0, 7) + ".." + accounts[0].slice(-5);
        this.setState({
            connectedAccount: accountslice
        })
        $("#hidedisconnect").show(); 
    }

    disconnectWeb3Modal = async () => {
        this.setState({
            connectedAccount: "CONNECT WALLET"
        })
        sessionStorage.removeItem('chainaccounts')
        window.location.reload();
    }

    getchainid = async () => {
        const chainaccounts = sessionStorage.getItem('chainaccounts')
        if (chainaccounts !== null) {
            $(".table-bsc").show();
            $('.switch-tabs-section-dcx').css('background', '#eac3686e');
            $('.switch-tabs-section-dcx').css('color', '#FFF');
            $('.switch-tabs-section-eth').css('background', 'transparent');
            $('.switch-tabs-section-eth').css('color', '#FFF');
            $('.switch-tabs-section-poly').css('background', 'transparent');
            $('.switch-tabs-section-poly').css('color', '#FFF');
            $('.switch-tabs-section-bsc').css('background', 'transparent');
            $('.switch-tabs-section-bsc').css('color', '#FFF');
            this.dcxtxdetails()
        }
    }


    bsctxdetails = async () => {
        const chainaccounts = sessionStorage.getItem('chainaccounts')
        if (chainaccounts !== null) {
            $(".table-bsc").show();
            $('.switch-tabs-section-dcx').css('background', 'transparent');
            $('.switch-tabs-section-dcx').css('color', '#FFF');
            $('.switch-tabs-section-eth').css('background', 'transparent');
            $('.switch-tabs-section-eth').css('color', '#FFF');
            $('.switch-tabs-section-poly').css('background', 'transparent');
            $('.switch-tabs-section-poly').css('color', '#FFF');
            $('.switch-tabs-section-bsc').css('background', '#eac3686e');
            $('.switch-tabs-section-bsc').css('color', '#FFF');

            const decoder = new InputDataDecoder(homebridgeAbi);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const gettxdetails = ['']
            const responres = await axios.get("https://api.bscscan.com/api?module=account&action=txlist&contractaddress=0x99f7d9247c2d8e78f3d67be54d460d9a798a02c6&address=" + account[0] + "&page=1&offset=100&sort=desc&apikey=NCN23G4SGIEYWR3XWGR3XXWKMXT61BPE4I");
            for (var i = 0; i < responres.data.result.length; i++) {
                if (responres.data.result[i].to == "0x99f7d9247c2d8e78f3d67be54d460d9a798a02c6") {

                    let unixTimestamp = responres.data.result[i].timeStamp;
                    const utcDateTime = moment.unix(unixTimestamp).utc().format('YYYY/MM/DD HH:mm:ss');

                    const resultinput = await decoder.decodeData(responres.data.result[i].input);

                    const hexToDecimal2 = (parseInt(resultinput.inputs[0]._hex, 16)) / 1e18;
                    var car = {
                        "sno": utcDateTime + " UTC",
                        "link": "https://bscscan.com/tx/" + responres.data.result[i].hash,
                        "hash": responres.data.result[i].hash,
                        "from": responres.data.result[i].from,
                        "to": responres.data.result[i].to,
                        "value": hexToDecimal2 + " USDT"
                    }
                    gettxdetails.push(car);
                    const setpaths = gettxdetails.filter(Boolean);
                    this.setState({
                        setgettxdetails: setpaths
                    })
                }
            }
            if (gettxdetails.length <= 1) {
                $(".table-bsc").hide();
            }
        } else {
            $('.switch-tabs-section-dcx').css('background', 'transparent');
            $('.switch-tabs-section-dcx').css('color', '#FFF');
            $('.switch-tabs-section-eth').css('background', 'transparent');
            $('.switch-tabs-section-eth').css('color', '#FFF');
            $('.switch-tabs-section-poly').css('background', 'transparent');
            $('.switch-tabs-section-poly').css('color', '#FFF');
            $('.switch-tabs-section-bsc').css('background', '#eac3686e');
            $('.switch-tabs-section-bsc').css('color', '#FFF');
        }
    }

    matictxdetails = async () => {
        const chainaccounts = sessionStorage.getItem('chainaccounts')
        if (chainaccounts !== null) {
            $(".table-bsc").show();
            $('.switch-tabs-section-dcx').css('background', 'transparent');
            $('.switch-tabs-section-dcx').css('color', '#FFF');
            $('.switch-tabs-section-eth').css('background', 'transparent');
            $('.switch-tabs-section-eth').css('color', '#FFF');
            $('.switch-tabs-section-poly').css('background', '#eac3686e');
            $('.switch-tabs-section-poly').css('color', '#FFF');
            $('.switch-tabs-section-bsc').css('background', 'transparent');
            $('.switch-tabs-section-bsc').css('color', '#FFF');
            this.state.setgettxdetails = []

            const decoder = new InputDataDecoder(homebridgeAbi);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const gettxdetails = ['']
            const response = await axios.get("https://api.polygonscan.com/api?module=account&action=txlist&contractaddress=0x99f7d9247c2d8e78f3d67be54d460d9a798a02c6&address=" + account[0] + "&startblock=0&endblock=99999999&page=1&offset=10&sort=desc&apikey=7VJGEDNEVBT9BAFVN2GHYSCZ1ZE1V5SYPS");
            for (var i = 0; i < response.data.result.length; i++) {
                const webchecksumaddr = response.data.result[i].to;
                if (webchecksumaddr === "0x99f7d9247c2d8e78f3d67be54d460d9a798a02c6") {
                    let unixTimestamp = response.data.result[i].timeStamp;
                    const utcDateTime = moment.unix(unixTimestamp).utc().format('YYYY/MM/DD HH:mm:ss');
                    const resultinput = decoder.decodeData(response.data.result[i].input);
                    const hexToDecimal2 = (parseInt(resultinput.inputs[0]._hex, 16)) / 1e18;
                    var bus = {
                        "sno": utcDateTime + " UTC",
                        "link": "https://polygonscan.com/tx/" + response.data.result[i].hash,
                        "hash": response.data.result[i].hash,
                        "from": response.data.result[i].from,
                        "to": response.data.result[i].to,
                        "value": hexToDecimal2 + " USDT"
                    }
                    gettxdetails.push(bus);
                    const setpaths = gettxdetails.filter(Boolean);
                    this.setState({
                        setgettxdetails: setpaths
                    })
                }
            }
            if (gettxdetails.length <= 1) {
                $(".table-bsc").hide();
            }
        } else {
            $('.switch-tabs-section-dcx').css('background', 'transparent');
            $('.switch-tabs-section-dcx').css('color', '#FFF');
            $('.switch-tabs-section-eth').css('background', 'transparent');
            $('.switch-tabs-section-eth').css('color', '#FFF');
            $('.switch-tabs-section-poly').css('background', '#eac3686e');
            $('.switch-tabs-section-poly').css('color', '#FFF');
            $('.switch-tabs-section-bsc').css('background', 'transparent');
            $('.switch-tabs-section-bsc').css('color', '#FFF');
        }
    }


    dcxtxdetails = async () => {
        const chainaccounts = sessionStorage.getItem('chainaccounts')
        if (chainaccounts !== null) {
            $(".table-bsc").show();
            $('.switch-tabs-section-eth').css('background', 'transparent');
            $('.switch-tabs-section-eth').css('color', '#FFF');
            $('.switch-tabs-section-poly').css('background', 'transparent');
            $('.switch-tabs-section-poly').css('color', '#FFF');
            $('.switch-tabs-section-bsc').css('background', 'transparent');
            $('.switch-tabs-section-bsc').css('color', '#FFF');
            $('.switch-tabs-section-dcx').css('background', '#eac3686e');
            $('.switch-tabs-section-dcx').css('color', '#FFF');

            const decoder = new InputDataDecoder(homebridgeAbi);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const gettxdetails = ['']
            const response = await axios.get("https://mainnet-explorer.d-ecosystem.io/api?module=account&action=txlist&contractaddress=0x99f7D9247c2D8e78f3d67be54D460D9A798a02c6&address=" + account[0] + "&page=1&offset=100&sort=desc")
                
            for (var i = 0; i < response.data.result.length; i++) {
                const webchecksumaddr = response.data.result[i].to;
                if (webchecksumaddr === "0x99f7d9247c2d8e78f3d67be54d460d9a798a02c6") {
                    let unixTimestamp = response.data.result[i].timeStamp;
                    const utcDateTime = moment.unix(unixTimestamp).utc().format('YYYY/MM/DD HH:mm:ss');
                       
                    const resultinput = decoder.decodeData(response.data.result[i].input);
                    const hexToDecimal2 = (parseInt(resultinput.inputs[0]._hex, 16)) / 1e18;
                    var bus = {
                        "sno": utcDateTime + " UTC",
                        "link": "https://mainnet-explorer.d-ecosystem.io/tx/" + response.data.result[i].hash,
                        "hash": response.data.result[i].hash,
                        "from": response.data.result[i].from,
                        "to": response.data.result[i].to,
                        "value": hexToDecimal2 + " DUSD"
                    }
                    gettxdetails.push(bus);
                    const setpaths = gettxdetails.filter(Boolean);
                    this.setState({
                        setgettxdetails: setpaths
                    })
                }
            }
            if (gettxdetails.length <= 1) {
                $(".table-bsc").hide();
            }
        }
        else {
            $('.switch-tabs-section-eth').css('background', 'transparent');
            $('.switch-tabs-section-eth').css('color', '#FFF');
            $('.switch-tabs-section-poly').css('background', 'transparent');
            $('.switch-tabs-section-poly').css('color', '#FFF');
            $('.switch-tabs-section-bsc').css('background', 'transparent');
            $('.switch-tabs-section-bsc').css('color', '#FFF');
            $('.switch-tabs-section-dcx').css('background', '#eac3686e');
            $('.switch-tabs-section-dcx').css('color', '#FFF');
        }
    }


    ethtxdetails = async () => {
        const chainaccounts = sessionStorage.getItem('chainaccounts')
        if (chainaccounts !== null) {
            $(".table-bsc").show();
            $('.switch-tabs-section-dcx').css('background', 'transparent');
            $('.switch-tabs-section-dcx').css('color', '#FFF');
            $('.switch-tabs-section-eth').css('background', '#eac3686e');
            $('.switch-tabs-section-eth').css('color', '#FFF');
            $('.switch-tabs-section-poly').css('background', 'transparent');
            $('.switch-tabs-section-poly').css('color', '#FFF');
            $('.switch-tabs-section-bsc').css('background', 'transparent');
            $('.switch-tabs-section-bsc').css('color', '#FFF');

            const decoder = new InputDataDecoder(homebridgeAbi);
            const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const gettxdetails = ['']
            const response = await axios.get("https://api.etherscan.io/api?module=account&action=txlist&contractaddress=0x99f7d9247c2d8e78f3d67be54d460d9a798a02c6&address=" + account[0] + "&page=1&offset=100&sort=desc&apikey=6GFI8X5AI2DP5VFITIBRRWDASRCCHYK18M")

            for (var i = 0; i < response.data.result.length; i++) {
                const webchecksumaddr = response.data.result[i].to;
                if (webchecksumaddr === "0x99f7d9247c2d8e78f3d67be54d460d9a798a02c6") {
                    let unixTimestamp = response.data.result[i].timeStamp;
                    const utcDateTime = moment.unix(unixTimestamp).utc().format('YYYY/MM/DD HH:mm:ss');
                    const resultinput = decoder.decodeData(response.data.result[i].input);
                    const hexToDecimal2 = (parseInt(resultinput.inputs[0]._hex, 16)) / 1e18;

                    var bus = {
                        "sno": utcDateTime + " UTC",
                        "link": "https://etherscan.io/tx/" + response.data.result[i].hash,
                        "hash": response.data.result[i].hash,
                        "from": response.data.result[i].from,
                        "to": response.data.result[i].to,
                        "value": hexToDecimal2 + " USDT"
                    }
                    gettxdetails.push(bus);
                    const setpaths = gettxdetails.filter(Boolean);
                    this.setState({
                        setgettxdetails: setpaths
                    })
                }
            }
            if (gettxdetails.length <= 1) {
                $(".table-bsc").hide();
            }
        } else {
            $('.switch-tabs-section-dcx').css('background', 'transparent');
            $('.switch-tabs-section-dcx').css('color', '#FFF');
            $('.switch-tabs-section-eth').css('background', '#eac3686e');
            $('.switch-tabs-section-eth').css('color', '#FFF');
            $('.switch-tabs-section-poly').css('background', 'transparent');
            $('.switch-tabs-section-poly').css('color', '#FFF');
            $('.switch-tabs-section-bsc').css('background', 'transparent');
            $('.switch-tabs-section-bsc').css('color', '#FFF');
        }
    }



    render() {
        return (
            <div className="App">
                <nav className="navbar navbar-expand-lg navbar-light navbar-section">
                    <div className="container">
                        <a className="navbar-brand" href="https://cuptoken.trade/"><img src={DIClogo} alt="logo" /></a>
                       
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <button className="nav-link connect-wallet-button  my-2" id="hideconnect" onClick={this.connectWeb3Wallet}>
                                <BsWallet2 className='me-2' />
                                {this.state.connectedAccount == "" || this.state.connectedAccount == null ? "CONNECT WALLET" : this.state.connectedAccount}
                            </button>
                            <button className="nav-link connect-wallet-button-2 my-2 ms-2" id="hidedisconnect" onClick={this.disconnectWeb3Modal}>
                                <BsWallet2 className='me-2' />
                                {this.state.connectedAccount !== "" ? "Disconnect" : ""}
                            </button>
                        </ul>
                    </div>
                </nav>
                {/* <button className="nav-link connect-wallet-button  my-2"  id="hideconnect" onClick={this.connectWeb3Wallet}>
                    <BsWallet2 className='me-2' />
                {this.state.connectedAccount == "" || this.state.connectedAccount == null  ? "CONNECT WALLET":this.state.connectedAccount} 
                </button> 
                <button className="nav-link connect-wallet-button-2 my-2 ms-2" id="hidedisconnect" onClick={this.disconnectWeb3Modal}>
                    <BsWallet2 className='me-2' />
                    {this.state.connectedAccount !== "" ? "Disconnect":""}
                </button> */}
                <section className='py-5'>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-12 switch-tabs-section'>
                                <div className="text-start mb-lg-0 mb-3">
                                    <a href="/"><span className="trans-back-button"><BiLeftArrowAlt /></span></a>
                                </div>
                                <div className="btn-group switch-tabs-btn-group">
                                    <a href="#dcx"><button type="button" onClick={this.dcxtxdetails} className="btn switch-tabs-section-dcx"><img className="network-image" src={dchainimg} alt="DCHAIN Network" />D-CHAIN Network</button></a>
                                    <a href="#eth"><button type="button" onClick={this.ethtxdetails} className="btn switch-tabs-section-eth"><img className="network-image" src={ethimg} alt="eth Network" />eth Network</button></a>
                                    <a href="#bsc"><button type="button" onClick={this.bsctxdetails} className="btn switch-tabs-section-bsc"><img className="network-image" src={bscimage} alt="BSC Network" />BSC Network</button></a>
                                    <a href="#matic"><button type="button" onClick={this.matictxdetails} className="btn switch-tabs-section-poly"><img className="network-image" src={maticimg} alt="POLYGON Network" />POLYGON Network</button></a>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-5">
                                <div className="table-bsc gSVZoG">
                                    <DataTable
                                        columns={this.columns}
                                        data={this.state.setgettxdetails}
                                        defaultSortFieldId
                                        pagination={5}
                                        highlightOnHover
                                        theme="solarized"
                                        onSort={this.handleSort}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default App;
