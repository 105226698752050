import React, { useState, useEffect } from 'react';
import './App.css';
import Bridge from "./Bridge"
import Transactions from "./Transactions"
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Errorpage from './Errorpage';
import { ToastContainer, toast } from "react-toastify";
import { WagmiConfig, createConfig, configureChains } from 'wagmi'

import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { mainnet, polygon, bsc } from "wagmi/chains";


function App() {

  const { chains, publicClient, webSocketPublicClient } = configureChains(
    [mainnet,polygon,bsc],
    [alchemyProvider({ apiKey: 'f4FBGOtlTPoqJ2ZR-zanG3k9lgU38KCu' }), publicProvider()],
  )

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: [
      new MetaMaskConnector({ chains }),
      new WalletConnectConnector({
        chains,
        options: {
          projectId: '1f0b9efb280db82bff5edc4689ffa76a',
        },
      }),
    ],
    publicClient,
    webSocketPublicClient,
  })

  return (
    <div className="App">
      <ToastContainer/>
      <WagmiConfig config={wagmiConfig}>
        <Router>
          <Routes>
            <Route path="/" element={<Bridge />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/*" element={<Errorpage/>}/>
          </Routes>
        </Router>
      </WagmiConfig>
    </div>
  );
}

export default App;
