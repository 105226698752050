import React, { useState, useEffect } from "react";
import { BsWallet2 } from "react-icons/bs";

import $ from "jquery";



const WalletConnector = () => { 

    const [connectedAccount, setconnectedAccount] = useState("")

    const connectWeb3Wallet = async() => {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        sessionStorage.setItem('chainaccounts', accounts[0])
        setconnectedAccount(accounts[0].slice(0,7)+".."+accounts[0].slice(-5))
        window.location.reload();
    }

    const disconnectWeb3Modal = async() => {
        setconnectedAccount('CONNECT WALLET')
        sessionStorage.removeItem('chainaccounts')
        window.location.reload();
    }

    useEffect(() => {
        const chainaccounts = sessionStorage.getItem('chainaccounts')
        if(chainaccounts == null) {
            $("#hidedisconnect").hide(); 
            $("#hideconnect").show(); 
            setconnectedAccount("CONNECT WALLET");
        }
        else {
            $("#hidedisconnect").show(); 
            setconnectedAccount(chainaccounts.slice(0,7)+".."+chainaccounts.slice(-5))
        }
    })


    return (
        <>
           <button className="nav-link connect-wallet-button  my-2"  id="hideconnect" onClick={connectWeb3Wallet}>
                    <BsWallet2 className='me-2' />
                {connectedAccount == "" || connectedAccount == null  ? "CONNECT WALLET":connectedAccount} 
            </button> 
            <button className="nav-link connect-wallet-button-2 my-2 ms-2" id="hidedisconnect" onClick={disconnectWeb3Modal}>
                <BsWallet2 className='me-2' />
                {connectedAccount !== "" ? "Disconnect":""}
            </button>
        </>
    );
};
export default WalletConnector;