let config = {};
let environment = "live";

if (environment === "local") {
  config = {
    // FRONTEND_URL : "http://localhost:3000/",
    FRONTEND_URL : "https://presale.cuptoken.trade/",
    // BACKEND_URL : "http://localhost:4000/dusdtokentransfer",
    BACKEND_URL : "https://api-presale.cuptoken.trade/dusdtokentransfer",

    ETH_BRIDGE_ADDRESS:"0x0bf826E6189B6Cb68A0642D52883f3A6AF485Ae2",
    BSC_BRIDGE_ADDRESS:"0xdF9aC3eEb501130c4869284f40C63096d2D00638",
    MATIC_BRIDGE_ADDRESS:"0xCDc16FA6bC43df2e485BDC0016327dfB1c5EEF8F",
    DCX_BRIDGE_ADDRESS:"0x5E8b59e5076296EA167539EaDCA9c7E6B7beF8e1",
    CUP_TOKEN_BRIDGE_ADDRESS: "0x2beD1cAA33A7f51e716cb2a1B8381471D0175C6E",

    web3dchainurl:"https://sepolia.infura.io/v3/be8a6c05d29e453fa05d354047f4e2f2",
    bsctestnetrpcurl:"https://data-seed-prebsc-1-s2.binance.org:8545",
    matictestnetrpcurl : "https://rpc-mumbai.maticvigil.com",
    dchainrpcurl : "https://goerli.base.org",

    bscblockExplorerUrls:"https://bsc-testnet.publicnode.com/",
    dchainblockExplorerUrls : "https://goerli.basescan.org",

    ETH_TOKEN_ADDRESS:"0x24fDE9cCc95cC43801AE7A71457AC9F7aE1809a9",
    BSC_TOKEN_ADDRESS:"0xe6Fc9E04AE701cf5BA109Ff7168A1510f5D3f768",
    MATIC_TOKEN_ADDRESS:"0x18f79a903a97606b0629dc974b772307dfb89617",
    DUSD_TOKEN_ADDRESS : "0x057f6b46c9618c05b1c86244b5950cb699d30aef",
    CUP_TOKEN_ADDRESS :"0x2f73Ef08442AAD93FF433670B7E010239a52DCfA",

    BSC_CHAIN_ID :"0x61",
    ETH_CHAIN_ID : "0xaa36a7",
    MATIC_CHAIN_ID : "0x13881",
    DCX_CHAIN_ID : "0x14a33"
  };
} else {
  config = {
    FRONTEND_URL : "https://presale.cuptoken.trade/",
    BACKEND_URL : "https://api-presale.cuptoken.trade/dusdtokentransfer",

    ETH_BRIDGE_ADDRESS:"0x99f7d9247c2d8e78f3d67be54d460d9a798a02c6",
    BSC_BRIDGE_ADDRESS:"0x99f7d9247c2d8e78f3d67be54d460d9a798a02c6",
    MATIC_BRIDGE_ADDRESS:"0x99f7d9247c2d8e78f3d67be54d460d9a798a02c6",
    DCX_BRIDGE_ADDRESS:"0x99f7d9247c2d8e78f3d67be54d460d9a798a02c6",

    CUP_TOKEN_BRIDGE_ADDRESS: "0x1E773a1a300F255d03FA48D60e8B3dE6089FB984",

    web3dchainurl:"https://mainnet.infura.io/v3/20f0e439013b492c9a149b08a32c33ad",
    bsctestnetrpcurl:"https://bsc-dataseed1.binance.org/",
    matictestnetrpcurl : "https://polygon-rpc.com/",
    dchainrpcurl : "https://mainnetcoin.d-ecosystem.io/",

    bscblockExplorerUrls :"https://bscscan.com/",
    dchainblockExplorerUrls : "https://mainnetcoin.d-ecosystem.io",

    ETH_TOKEN_ADDRESS:"0xdAC17F958D2ee523a2206206994597C13D831ec7",
    BSC_TOKEN_ADDRESS:"0x55d398326f99059ff775485246999027b3197955",
    MATIC_TOKEN_ADDRESS:"0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    DUSD_TOKEN_ADDRESS : "0x330555a02Ab09344b86D1c9282af1b1bCB51cafe",

    CUP_TOKEN_ADDRESS :"0xF1337bf4DB0cAc498C63B0091BC796fE8bA278eF",

    BSC_CHAIN_ID :"0x38",
    ETH_CHAIN_ID : "0x1",
    MATIC_CHAIN_ID : "0x89",
    DCX_CHAIN_ID : "0x1498"
  };
}

export default config;